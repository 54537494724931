import { Classes } from "@blueprintjs/core";
import styled, { css } from "styled-components";

import { HexButton } from "../../../hex-components";
import { ListFilter } from "../../common/ListFilter.js";
import { CrossSmallIcon } from "../../icons/CustomIcons";

const TreeNodeHeight = 24;

export const SchemaElementRow = styled.div<{
  $readOnly?: boolean;
  $pseudoHover?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 15px;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};

  font-size: ${({ theme }) => theme.fontSize.SMALL};

  ${({ $pseudoHover, theme }) =>
    $pseudoHover && `background-color: ${theme.hoverColor};`}

  ${({ $readOnly, theme }) =>
    !$readOnly &&
    css`
      cursor: pointer;

      transition: background-color ${theme.animation.duration}
        ${theme.animation.easing};

      &:hover {
        background-color: ${theme.hoverColor};
      }

      &:active {
        background-color: ${theme.activeColor};
      }
    `}
`;

export const SchemaElementContainer = styled.div`
  overflow-y: auto;
`;

export const ColumnTypeBadgeWrapper = styled.div`
  display: flex;
  flex: none;
  gap: 4px;
  align-items: center;
  max-width: 30%;
`;

export const TextContainer = styled.div`
  flex: auto;
  min-width: 0;
  margin-left: 4px;

  &:first-child {
    margin-left: 0;
  }
`;

export const ColumnTypeBadge = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  height: 18px;
  padding: 0 3px;

  color: ${({ theme }) => theme.fontColor.MUTED};

  font-size: 10px;
  line-height: 18px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px 0px 0px;
`;

export const RichSwitchWrapper = styled.div`
  padding: 0 15px;
`;

export const AllConnectionsButton = styled(HexButton)`
  &&&&& {
    align-self: flex-start;
    margin-left: 6px;
  }
`;

export const SearchContainer = styled.div<{
  $searchFocused: boolean;
  $inDataBrowser?: boolean;
}>`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding: ${({ $inDataBrowser }) =>
    $inDataBrowser ? "8px 8px 10px 16px" : "2px 10px 5px 15px"};

  input:not(:focus) {
    color: ${({ $searchFocused, theme }) =>
      $searchFocused ? theme.fontColor.DEFAULT : theme.fontColor.MUTED};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex: none;
  align-items: center;
`;

export const SelectedTreeNodeStyles = css`
  .${Classes.TREE_NODE}.${Classes.TREE_NODE_SELECTED} {
    > .${Classes.TREE_NODE_CONTENT} {
      z-index: 1;

      color: ${({ theme }) => theme.menuItem.activeText};
      background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${({ theme }) => theme.menuItem.activeBackground};
      }

      .${Classes.ICON} > svg {
        color: ${({ theme }) => theme.menuItem.activeText};
      }

      .${Classes.TREE_NODE_CARET} {
        svg path {
          stroke: ${({ theme }) => theme.backgroundColor.DEFAULT};
        }
      }
    }
  }
  .${Classes.TREE_NODE_CARET_NONE} {
    min-width: 18px;
  }
`;

export const ColumnsPanelHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 12px 15px;
`;

export const ColumnsPanelHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  line-height: 20px;
`;

export const ColumnsPanelHeaderInnerInfoContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  min-width: 0;

  font-family: ${({ theme }) => theme.fontFamily.MONO};
`;

export const ColumnsPanelHeaderActions = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

export const XIcon = styled(CrossSmallIcon)`
  margin: 0 4px;
`;

export const Highlight = styled.span`
  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  background-color: ${({ theme }) => theme.searchHighlightColor};
`;

export const StyledPinButton = styled(HexButton)<{ $pinned: boolean }>`
  && .${Classes.ICON} {
    opacity: 0.6;
  }
  &:hover {
    && .${Classes.ICON} {
      opacity: 1;
    }
  }
  ${({ $pinned, theme }) =>
    $pinned &&
    theme &&
    css`
      &&& .${Classes.ICON} {
        opacity: 1;
        svg {
          color: ${theme.highlightColor};
        }
      }
    `}
`;

export const ColumnSearchResultContainer = styled.div`
  z-index: 1;

  display: flex;
  justify-content: space-between;

  pointer-events: none;
`;

export const BreadcrumbTextContainer = styled.div`
  display: flex;
  flex: initial;
  align-items: center;
  min-width: 0;
  margin-left: 4px;
`;

export const ColumnSearchResultsWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  padding: 8px;
`;

export const Breadcrumbs = styled.div`
  z-index: 1;

  display: flex;
  gap: 3px;
  margin-top: 4px;

  pointer-events: none;
`;

export const ColumnSearchResultClickArea = styled.div<{ $selected: boolean }>`
  position: absolute;

  cursor: pointer;

  transition: background-color ${({ theme }) => theme.animation.duration}
    ${({ theme }) => theme.animation.easing};
  inset: 0 0 0 0;

  &:hover {
    ${({ $selected, theme }) =>
      !$selected && `background-color: ${theme.hoverColor};`}
  }

  ${({ $selected, theme }) =>
    $selected && `background-color: ${theme.menuItem.activeBackground};`}
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Description = styled.div`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;

  overflow: hidden;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  line-height: 16px;

  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

export const ColumnRowWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 4px;
  min-width: 0;
`;

export const ColumnRowTop = styled.div`
  display: flex;
  align-items: center;

  min-height: 20px;

  font-family: ${({ theme }) => theme.fontFamily.MONO};
`;

export const SchemaWrapperDiv = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 0;
  margin-top: 6px;
`;

export const TreeWrapper = styled.div<{
  $panelOpen: boolean;
  $inDialog: boolean;
}>`
  width: 100%;
  height: 100%;
  margin-top: 4px;

  overflow-x: hidden;
  overflow-y: auto;

  font-size: ${({ theme }) => theme.fontSize.SMALL};

  .${Classes.TREE_NODE_CARET_OPEN}, .${Classes.TREE_NODE_CARET_CLOSED} {
    /* This is a bit of a hack to make blueprints chevron look more like ours */
    opacity: 0.75;
    svg path {
      stroke: ${({ theme }) => theme.backgroundColor.LOGIC};
      stroke-width: 1px;
    }
  }

  .${Classes.TREE_NODE_CONTENT}-0 {
    position: sticky;
    top: 0;
    z-index: 1;
    padding-left: 6px;
    background-color: ${({ $inDialog, theme }) =>
      $inDialog ? theme.backgroundColor.DEFAULT : theme.backgroundColor.LOGIC};
  }

  .${Classes.TREE_NODE_CONTENT}-1 {
    position: sticky;
    top: ${TreeNodeHeight}px;
    z-index: 1;

    padding-left: 15px;
    background-color: ${({ $inDialog, theme }) =>
      $inDialog ? theme.backgroundColor.DEFAULT : theme.backgroundColor.LOGIC};
  }

  .${Classes.TREE_NODE_CONTENT}-2 {
    width: 100%;
    padding: 0 2px 0 10px;

    .${Classes.TREE_NODE_CARET_NONE} {
      display: none;
    }
  }

  .${Classes.TREE_NODE_LABEL} {
    margin-left: 4px;

    font-family: ${({ theme }) => theme.fontFamily.MONO};
  }

  .${Classes.COLLAPSE_BODY} {
    position: relative;
    width: 100%;
  }

  /* Left padding for the third level (tables) */
  .${Classes.COLLAPSE_BODY} .${Classes.COLLAPSE_BODY} {
    .${Classes.TREE_NODE_LIST}
      .${Classes.TREE_NODE}
      .${Classes.TREE_NODE_CONTENT} {
      padding-left: 54px;
    }
  }

  .${Classes.TREE_NODE} {
    position: relative;

    cursor: pointer;

    .${Classes.TREE_NODE_CONTENT} {
      height: ${TreeNodeHeight}px;

      &.pseudo-hover {
        background-color: ${({ theme }) => theme.NonTransparentHoverColor};
      }

      &:hover {
        background-color: ${({ theme }) => theme.NonTransparentHoverColor};

        ${StyledPinButton} {
          opacity: 1;
        }

        .${Classes.TREE_NODE_CARET} {
          svg path {
            stroke: ${({ theme }) => theme.NonTransparentHoverColor};
          }
        }
      }

      > * {
        z-index: 1;
      }
      .${Classes.TREE_NODE_CARET_NONE} {
        min-width: 22px;

        padding: 3px;
      }
      .${Classes.TREE_NODE_CARET} {
        min-width: 22px;
        min-height: 22px;
        padding: 3px;

        transition: transform ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing};

        &:hover {
          color: ${({ theme }) => theme.fontColor.MUTED};
        }
      }

      .${Classes.TREE_NODE_LABEL} {
        user-select: text;
      }
    }

    .${Classes.COLLAPSE_BODY} {
      transition: transform ${({ theme }) => theme.animation.duration}
        ${({ theme }) => theme.animation.easing};
    }
  }

  ${SelectedTreeNodeStyles}
`;

export const ColumnsPanelContainer = styled.div<{ $panelOpen: boolean }>`
  width: 100%;

  height: ${({ $panelOpen }) => ($panelOpen ? "100%" : 0)};
  overflow-y: auto;

  border-top: 1px solid ${({ theme }) => theme.borderColor.MUTED};

  opacity: ${({ $panelOpen }) => ($panelOpen ? 1 : 0)};

  transition: opacity ${({ theme }) => theme.animation.duration}
    ${({ theme }) => theme.animation.easing};
`;

export const StyledListFilter = styled(ListFilter)`
  && {
    flex: 1 1 auto;
  }
`;

/** Node Styling */
export const LabelIconContainer = styled.div`
  display: flex;
`;

export const LabelTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  color: inherit;

  span {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

/** Schema Table Panel Style */
export const LoadingSkeleton = styled.div`
  margin-right: 20px;

  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const LoadMore = styled.span`
  color: ${({ theme }) => theme.fontColor.LINK};
`;
